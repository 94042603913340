<template>
  <div class="gamedownload">
    <img
      src="https://cdn.bubbleplan.cn/static/home/back_cc.png"
      alt=""
      class="gamedownload-bach"
      @click.stop="back()"
    />
    <div class="gamedownload-box flex flex-direction items-center">
      <img
        src="https://cdn.bubbleplan.cn/static/home/gamedownload_title.png"
        alt=""
        class="gamedownload-box-img"
      />
      <img
        src="https://cdn.bubbleplan.cn/static/home/gamedownload_border.png"
        alt=""
        class="gamedownload-box-img2"
      />
      <div class="gamedownload-button flex">
        <img
          src="https://cdn.bubbleplan.cn/static/home/Group9240.png"
          alt=""
          class="gamedownload-button-img"
        />
        <div>
          <div class="gamedownload-button-box flex" @click="download(0)">
            <img
              src="https://cdn.bubbleplan.cn/static/home/Ve12313ctor.png"
              alt=""
              class="gamedownload-button-box-img"
            />
            <div>IOS下载</div>
          </div>
          <div class="gamedownload-button-box flex" @click="download(1)">
            <img
              src="https://cdn.bubbleplan.cn/static/home/Ve123123ctor.png"
              alt=""
              class="gamedownload-button-box-img"
            />
            <div>Android下载</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    download(type) {
      if (type == 0) {
        // location.href = `https://apps.apple.com/cn/app/%E6%B3%A1%E6%B3%A1%E8%AE%A1%E5%88%92/id6636481546`;
        window.open(
          "https://apps.apple.com/cn/app/%E6%B3%A1%E6%B3%A1%E8%AE%A1%E5%88%92/id6636481546"
        );
      } else {
        location.href = `https://cdn.bubbleplan.cn/apk/%E6%B3%A1%E6%B3%A1%E8%AE%A1%E5%88%92.apk?v=${new Date().getTime()}`;
      }
    },
    back() {
      history.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.gamedownload {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/home/gamedownload_bgc.png")
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  padding-top: 40vh;
  &-bach {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 76px;
    left: 90px;
    z-index: 9;
  }
  &-bach:hover {
    cursor: pointer;
  }
  &-box {
    &-img {
      width: 422px;
      height: 141px;
    }
    &-img2 {
      width: 764px;
      height: 48px;
    }
  }
  &-button {
    margin-top: 23px;
    align-items: center;
    &-img {
      width: 211px;
      height: 95px;
      margin-right: 33.58px;
    }
    &-box:nth-child(2) {
      margin-top: 26.51px;
    }
    &-box:hover {
      cursor: pointer;
    }

    &-box {
      width: 228.88px;
      height: 62.74px;
      flex-shrink: 0;
      border-radius: 7.95px;
      border: 4.42px solid #fff;
      background: #021521;
      color: #ffffff;
      font-size: 20px;
      align-items: center;
      justify-content: center;
      &-img {
        width: 29px;
        height: 36px;
        margin-right: 13px;
      }
    }
  }
}
</style>